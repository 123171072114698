import { styles } from "@/components/installementStyle";
import { colors } from "@/utils/const";
import { Box, Button, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import en from "../../../locales/en";
import ar from "../../../locales/ar";

export default function custome404() {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  return (
    <Box
      sx={{
        m: "0 auto",
        py: { xs: 2, md: 4 },
      }}
    >
      <Box
        src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/errorLogo.png"
        alt="Error"
        component="img"
        sx={{
          maxWidth: { xs: "250px", lg: "500px", md: "500px", sm: "500px" },
          maxHeight: { xs: "250px", lg: "500px", md: "500px", sm: "500px" },
        }}
        style={{
          display: "block",
          opacity: "50%",
          margin: "0 auto",
          width: "500px",
          height: "500px",
        }}
      ></Box>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          color: colors.blue,
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {t.errorPage.title}{" "}
      </Typography>

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          <Button
            aria-label="Back to Home page"
            sx={styles.installementButton}
            style={{ width: "230px", height: "40px", marginTop: "20px" }}
            variant="contained"
            onClick={() => router.push("/")}
          >
            {t.errorPage.Home}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
