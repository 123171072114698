import { colors } from "../utils/const";

export const styles = {
  box: {
    bgcolor: colors.white,
    p: "16px",
    color: colors.dark_charcoal,
    borderRadius: "20px",
    boxShadow:
      " 0px 0px 5px 0px rgba(4, 9, 33, 0.03), 0px 2px 0px 0px rgba(4, 9, 33, 0.05), 0px 2px 10px 0px rgba(4, 9, 33, 0.05);",
  },
  title: {
    fontSize: { xs: "16px", sm: "20px" },
    fontWeight: 700,
  },
  boldStyle: (theme) => ({
    fontWeight: 700,
  }),
  payButton: (theme) => ({
    color: colors.white,
    border: `1px solid ${colors.orange}`,
    backgroundColor: colors.orange,
    borderRadius: "8px",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: colors.orange,
      color: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  }),
  card: {
    borderRadius: "16px",
    // ml:"16px",
    mb: "8px",
    padding: "16px 12px",
    // gap: "12px",
    backgroundColor: "#F8F8F8",
    boxShadow:
      "0px 0px 5px 0px rgba(4, 9, 33, 0.03), 0px 2px 0px 0px rgba(4, 9, 33, 0.05), 0px 2px 10px 0px rgba(4, 9, 33, 0.05)",
  },
  smallHead: {
    fontSize: "14px",
    fontWeight: "300",
    mb: "2px",
  },
  currency: {
    color: colors.manhattan_blue,
    paddingRight: "2px",
  },
  prograss: {
    borderRadius: "16px",
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    height: "6px",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#686B7A",
    },
  },
  installementButton: (theme) => ({
    color: colors.white,
    border: `1px solid ${colors.blue}`,
    backgroundColor: colors.blue,
    borderRadius: "8px",
    fontSize: "14px !important",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: colors.blue,
      color: colors.white,
      border: `1px solid ${colors.blue}`,
    },

    "&:disabled": {
      color: "rgba(255,255,255,0.5)",
      // bgcolor: "red",
      backgroundColor: "#AFB0B8",
      border: `1px solid #AFB0B8`,
    },
  }),
  midHead: {
    fontSize: { xs: "14px", sm: "15px", md: "16px" },
    fontWeight: "400",
    color: colors.bastille,
  },
  tabelHead: {
    color: colors.manhattan_blue,
    fontWeight: 700,
    fontSize: "16px",
  },

  profileBtn: {
    backgroundColor: "white",
    color: "#344054",
    border: "1px solid #344054",
    borderRadius: "8px",
    mt: "8px",
    mb: "24px",
    width: "160px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  input: {
    color: "#667085",
    fontWeight: 300,
    fontSize: "16px",
  },
};
